import { Container, Alert } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from 'react-i18next';
import { Header } from './shared/Header';
import { Routing } from './Routing';
import { Features } from './shared/Features';
import { Attribution } from './shared/Attribution';
import { theme } from './theme';
import { HashRouter } from 'react-router-dom';
import { useState } from 'react';

const App = () => {
  const { t } = useTranslation();
  const [infoBox, setInfobox] = useState(localStorage.getItem('infoBox') ?? 'true')
  // TODO: Removed in future version.
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }

  const closeInfobox = () => {
    setInfobox('false')
    localStorage.setItem('infoBox', "false")
  }


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HashRouter>
          <Header/>
          <Container sx={{ backgroundColor: 'white', paddingTop:4}} maxWidth={'lg'}>
            {infoBox === 'true' &&
              <Alert sx={{ marginBottom: 5}} variant="outlined" severity="info" onClose={() => closeInfobox()}>
                {t('header.notification')}
              </Alert>
            }
            <Routing />
            <Features />
            <Attribution />
          </Container>
        </HashRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
