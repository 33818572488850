import { createTheme } from '@mui/material/styles';
import OttoSansRegular from '../public/fonts/OTTOSans-Regular.woff';
import OttoSansBold from '../public/fonts/OTTOSans-Bold.woff';

const ottoColors = {
  50: '#eceff1',
  100: '#cfd8dc',
  200: '#b0bec5',
  300: '#90a4ae',
  400: '#78909c',
  500: '#f00020',
  600: '#546e7a',
  700: '#bd0019',
  800: '#37474f',
  900: '#263238',
  A100: '#cfd8dc',
  A200: '#b0bec5',
  A400: '#78909c',
  A700: '#bd0019',
};

export const theme = createTheme({
  palette: {
    primary: ottoColors,
    background: {
      default: '#f0f0f0',
      paper: '#fff',
    },
  },
  typography: {
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    fontFamily: [
      'OTTOSans',
      'Arial',
      'Helvetica',
      'sans-serif'
    ].join(','),
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          "legend": {
            fontSize: '10px',
            textTransform: 'uppercase',
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'OTTOSans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('OTTOSans'), local('OTTOSans-Regular'), url(${OttoSansRegular}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        };

        @font-face {
          font-family: 'OTTOSans';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('OTTOSans'), local('OTTOSans-Bold'), url(${OttoSansBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});
